<template>
  <div v-if="isActive" :id="anchor">
    <div v-if="title" class="mb-5 text-h3 text-center">{{ title }}</div>
    <div class="beige-pastel-light text-center pa-4">
      <cms-util-link
        class="
          d-flex
          mx-auto
          white
          flex-column
          justify-center
          align-center
          rounded-circle
          text-decoration-none
        "
        style="height: 180px; width: 180px"
        wrapped="div"
        :url="url"
      >
        <base-icon
          class="mt-n4"
          size="100px"
          svg="M12.4,14c0,0.2,0,0.4,0,0.5c-0.7,0.2-3.8,0.5-4.8-0.8c-0.5-0.6-0.3-1.5,0.2-2c0.6-0.4,1.3-0.4,2-0.2c0-0.3,0.2-0.6,0.5-0.9c0.3-0.2,1-0.4,1.6,0.3c0,0.1,0.1,0.1,0.1,0.2c-0.2,0-0.5,0.1-0.7,0.3c-0.4,0.3-0.7,1-0.1,1.8C11.4,13.5,11.9,13.8,12.4,14zM15.1,9.7c-0.5-0.6-1.4-0.7-1.9-0.2s-0.6,1.1-0.7,1.8c-0.3,0-0.6,0-0.9,0.2c-0.3,0.2-0.6,0.9-0.1,1.5c0.7,0.9,2.9,1.3,3.3,1.4C15.1,13.8,16.1,11,15.1,9.7M20.5,10c0.1,0.2,0.2,0.5,0.1,0.6c-2.2,2.8-10,10.2-10,10.2c-0.1,0.1-0.4,0.2-0.6,0c0,0-1.8-1.9-1.8-1.9c0,0,0-0.1,0-0.2c0.3-0.4,0.5-0.8,0.5-1.3c0.1-1.6-1.8-2.6-3.2-1.5c-0.1,0.1-0.2,0.1-0.2,0l-1.6-1.6c-0.3-0.3-0.1-0.5,0-0.6c3.3-3.5,6.6-6.6,10-10c0.2-0.2,0.5-0.2,0.6,0c0.5,0.5,1,1.1,1.6,1.6c0.1,0.1,0.2,0.4,0,0.5c-0.6,0.7-0.6,1.8,0.1,2.5c0.6,0.7,1.6,0.6,2.3,0.1c0.2-0.1,0.4,0,0.6,0.1C18.8,8.5,20.2,9.5,20.5,10zM19.8,10l-1.2-1c-0.2-0.2-0.4-0.1-0.6,0c-0.5,0.4-2,0.3-2.7-0.4c-0.8-0.7-0.7-1.9-0.1-2.8c0.1-0.1,0.1-0.1,0-0.3c-0.2-0.3-0.6-0.8-1.2-1.1c-0.1,0-0.2,0-0.3,0.1c-1.1,0.6-6.4,6.2-9.6,9.4C4.1,14,4,14.1,4.3,14.4c0,0,0.6,0.7,0.8,0.9c0.1,0.1,0.2,0.2,0.3,0.2c2.1-1.2,4.6,1.3,3.1,3.3c0,0,0,0.1,0,0.1l1.3,1.4c0.1,0.1,0.2,0.2,0.3,0.1c2.9-3.1,7.9-8,9.4-9.5C19.9,10.6,20,10.3,19.8,10z"
        ></base-icon>
        <div class="pink-berry--text text-h2">{{ $n(sales, 'short') }}</div>
      </cms-util-link>
      <div v-if="subTitle" class="mt-3 text-body-2">{{ subTitle }}</div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
import { getCmsSlotConfigProperty, isCmsSlotActive } from '~/helpers'
export default {
  name: 'CmsElementBsProductSaleCounter',
  components: { BaseIcon },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    anchor() {
      return getCmsSlotConfigProperty(this.content, 'anchor') || undefined
    },
    isActive() {
      return isCmsSlotActive(this.content)
    },
    title() {
      return getCmsSlotConfigProperty(this.content, 'title')
    },
    subTitle() {
      return getCmsSlotConfigProperty(this.content, 'subtitle')
    },
    url() {
      const url = this.content.data?.seoUrl?.seoPathInfo

      return url ? `/${url}` : undefined
    },
    sales() {
      return this.content.data?.sales || 0
    },
  },
}
</script>
